<template>
    <v-app>
        <div style=" background:#dfe6e9;height: 100%">
            <div style="max-width:960px;     margin:0 auto">
                <v-card :loading="isLoading"
                    style="background:white; border-radius:5px; margin-top:40px;margin-left:5px;margin-right:5px;padding:10px">
                    <v-img style="max-width: 200px;
    margin: 0 auto;" src="/assets/logo.png" class="grey lighten-2"></v-img>
                    <v-file-input style="margin-top:10px" dense outlined accept="image/png, image/jpeg, image/bmp"
                        :multiple="true" @change="onFileInputChanged" v-model="files" placeholder="Chọn ảnh"
                        append-icon="mdi-image-multiple" label="Chọn ảnh">
                    </v-file-input>
                    <v-checkbox label="Chèn logo?" v-model="isInsertLogo"></v-checkbox>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-select v-model="logoPosition.y" hide-details dense :items="yItems"
                                label="Vị trí: Chiều dọc" outlined></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="logoPosition.x" hide-details dense :items="xItems"
                                label="Vị trí: Chiều ngang" outlined></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="logoPosition.size" hide-details dense :items="sizeItems"
                                label="Kích thước" outlined></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="logoPosition.opacity" hide-details dense :items="opacityItems"
                                label="Độ trong suốt" outlined></v-select>
                        </v-col>
                    </v-row>
                    <v-checkbox label="Chèn chữ GoldFruit?" v-model="isInsertText"></v-checkbox>
                  <v-checkbox label="Chèn SĐT?" v-model="isInsertPhone"></v-checkbox>
                  <div>
                    <v-text-field
                        label="SĐT"
                        placeholder="SĐT"
                        outlined
                        dense
                        v-model="phone"
                    ></v-text-field>
                  </div>
                    <v-btn v-if="logo64.width && txtInsert" @click="handle()" :loading="isLoading" style="margin: 0 auto;
    display: block;" tile color="indigo" dark>{{txtInsert}}</v-btn>
                    <div role="alert" class="alert alert-info" style="margin:10px" v-if="isLoading">
                        <div class="alert-text" style="text-align: center;">
                            <v-progress-circular indeterminate color="green"></v-progress-circular> Đang xử lý hình ảnh,
                            xin
                            hãy đợi............
                        </div>
                    </div>
                    <div>
                        <v-row v-if="files&&isDownloadAllShow">
                            <v-col v-for="n in files.length" :key="n" class="d-flex child-flex" cols="6" xs="6" sm="4"
                                md="4" lg="3">
                                <img style="object-fit: contain; max-height: 250px;" class="img-combine"
                                    :id="'img-combined-'+n" />
                            </v-col>
                        </v-row>
                    </div>
                    <v-btn v-if="!isLoading&&isDownloadAllShow" @click="downloadAll()" style="margin: 0 auto;
    display: block;" tile color="indigo" dark>Tải xuống toàn bộ</v-btn>
                </v-card>
            </div>
        </div>
    </v-app>
</template>
<script>
    import mergeImages from "merge-images";
    function toDataUrl(url) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      })
    }
    function textToImage(str, fontSize, maxwidth) {
      return new Promise((resolve, reject) => {
        var canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
        console.log( ctx.measureText(str))
        canvas.height = fontSize * 1.2;
        canvas.width = maxwidth
        var image = new Image();
        ctx.font = `${fontSize}px 'Roboto Mono'`;
        ctx.fillStyle = "white";
        // ctx.canvas.height = 600;

        ctx.textAlign = "center";
        ctx.fillText(str, canvas.width/2, canvas.height * 1.3 /2);
        image.src = ctx.canvas.toDataURL();
        image.onload = function () {
          resolve(this);
        };
        image.onerror = function (err) {
          console.log(err);
          reject(new Error("errorimg"));
        };
      });
    }
    function imageToDataUri(img, width, height) {
        return new Promise((resolve, reject) => {
            var canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
            canvas.width = width;
            canvas.height = height;
            var image = new Image();
            image.onload = function () {
                ctx.drawImage(image, 0, 0, width, height);
                resolve(canvas.toDataURL());
            };
            image.onerror = function (err) {
                console.log(err);
                reject(new Error("errorimg"));
            };
            image.src = img;
        });
    }
    async function b64toImg(str) {
      if (typeof str === 'string' || str instanceof String) {
        return new Promise((resolve) => {
          var image = new Image();
          image.src = str;
          image.onload = function () {
            resolve(this)
          }
        })
      }
      return str;
    }
    function loadImageFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            resolve(this)
          }
          image.onerror = function () {
            reject(new Error('sdfsf'));
          }
        };
        reader.readAsDataURL(file);
      });
    }
    function getXRight(imgWidth, logoWidth) {
        return imgWidth - logoWidth * 1.1;
    }
    function getXCenter(imgWidth, logoWidth) {
        return imgWidth * 0.5 - logoWidth * 0.5;
    }
    function getXLeft(imgWidth, logoWidth) {
        return logoWidth * 0.1;
    }
    function getYTop(imgHeight, logoHeight) {
        return logoHeight * 0.1;
    }
    function getYMiddle(imgHeight, logoHeight) {
        return imgHeight * 0.5 - logoHeight * 0.5;
    }
    function getYBottom(imgHeight, logoHeight) {
        return imgHeight - logoHeight * 1.1;
    }
    const phone = localStorage.getItem('phone');
    export default {
        data() {
            return {
                files: null,
                isDownloadAllShow: false,
                logo64: {
                    width: null,
                    height: null,
                    src: null,
                },
                gfText64: {
                    baseWidth: 492,
                    baseHeight: 82,
                    src: null,
                },
                logoPosition: {
                    x: null,
                    y: null,
                    opacity: null,
                    size: null,
                },
                isLoading: false,
                xItems: [
                    { text: 'Trái', value: 'left' },
                    { text: 'Giữa', value: 'center' },
                    { text: 'Phải', value: 'right' }
                ],
                yItems: [
                    { text: 'Trên', value: 'top' },
                    { text: 'Giữa', value: 'middle' },
                    { text: 'Dưới', value: 'bottom' }
                ],
                opacityItems: [],
                sizeItems: [],
                opacities: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                sizes: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                isInsertLogo: true,
                isInsertText:true,
              phone: phone,
              isInsertPhone: true,

            };
        },
        created() {
            this.opacityItems = this.opacities.sort(function (a, b) { return b - a }).map(o => {
                return {
                    text: o * 100 + '%',
                    value: o
                }
            })
            this.sizeItems = this.sizes.map(o => {
                return {
                    text: o * 100 + '% chiều ngang',
                    value: o
                }
            })
            this.logoPosition.x = 'right';
            this.logoPosition.y = 'top';
            this.logoPosition.size = 0.2;
            this.logoPosition.opacity = 1;
            var sef = this;
            sef.isLoading = true;
            toDataUrl("/assets/logo.png").then((myBase64) => {
                sef.logo64.src = myBase64;
                let i = new Image();

                i.onload = function () {
                    sef.isLoading = false;
                    sef.logo64.width = i.width;
                    sef.logo64.height = i.height;
                };

                i.src = myBase64;
            });
        },
        computed: {
            txtInsert() {
                let arr = [];
                if(this.isInsertLogo){
                    arr.push('logo')
                }
                if(this.isInsertText){
                    arr.push('chữ');
                }
              if(this.isInsertPhone){
                arr.push('SĐT');
              }
                if (arr.length == 0) {
                  return null
                }else {
                  return arr.join(' & ')
                }
            }
        },
        methods: {
            onFileInputChanged() {
                this.isDownloadAllShow = false;
            },
            async insertLogo(n, image) {
                let sef = this;
              var width = image.width;
              var logoWidth = width * sef.logoPosition.size;
              var logoHeight = sef.logo64.height * (logoWidth / sef.logo64.width);

              var xPosition;
              var yPosition;
              if (sef.logoPosition.x == 'left') {
                xPosition = getXLeft(width, logoWidth)
              } else if (sef.logoPosition.x == 'center') {
                xPosition = getXCenter(width, logoWidth)
              } else {
                xPosition = getXRight(width, logoWidth)
              }

              if (sef.logoPosition.y == 'top') {
                yPosition = getYTop(image.height, logoHeight)
              } else if (sef.logoPosition.y == 'middle') {
                yPosition = getYMiddle(image.height, logoHeight)
              } else {
                yPosition = getYBottom(image.height, logoHeight)
              }
              let newLogo64 = await imageToDataUri(sef.logo64.src, logoWidth, logoHeight);
              let b64 = await mergeImages([
                image.src,
                { src: newLogo64, x: xPosition, y: yPosition, opacity: sef.logoPosition.opacity },
              ],{format: 'image/jpeg'});
              return b64;
            },
          async insertPhone(n, image) {
              localStorage.setItem('phone', this.phone)
            let sef = this;
            image = await b64toImg(image)
            var fontsize = Math.round(image.height * 0.04);
            const textImg = await textToImage(sef.phone, fontsize, image.width)
            var xPosition;
            var yPosition;
              xPosition = 0
              yPosition = getYBottom(image.height, textImg.height)
            let b64 = await mergeImages([
              image.src,
              { src: textImg.src, x: xPosition, y: yPosition, opacity: 0.8 },
            ],{format: 'image/jpeg'});
            return b64;
          },
            async insertText(n, image) {
              image = await  b64toImg(image)
              var sef = this;
              var width = image.width;
              var height = image.height;
              var textHeight = height * 0.025;
              var numText = width / (textHeight * (sef.gfText64.baseWidth / sef.gfText64.baseHeight));
              numText = Math.floor(numText);
              var textWidth = (textHeight * (sef.gfText64.baseWidth / sef.gfText64.baseHeight))*numText;
              const textBase64 =await toDataUrl('/assets/gf-text/gf-text'+numText+'.png')
                const newTextBase64 = await imageToDataUri(textBase64, textWidth, textHeight)
                  var xPosition = getXCenter(width, textWidth);
                  var yPosition = getYMiddle(height, textHeight);
                  return await mergeImages([
                    image.src,
                    { src: newTextBase64, x: xPosition, y: yPosition, opacity: 0.6 },
                  ],{format: 'image/jpeg'});
            },
            async handle() {
                if (this.files) {
                    this.isDownloadAllShow = true;
                    this.isLoading = true;
                    for (let i in this.files) {
                        // try {

                        let imgRes = await loadImageFile(this.files[i]);
                        if (this.isInsertLogo) {
                          imgRes = await this.insertLogo(Number(i) + 1, imgRes);
                        }
                        if (this.isInsertText) {
                          imgRes = await this.insertText(Number(i) + 1, imgRes);
                        }
                      if (this.isInsertPhone) {
                        imgRes = await this.insertPhone(Number(i) + 1, imgRes);
                      }

                      var img = document.getElementById("img-combined-" + (Number(i) + 1));
                      img.src = imgRes;
                      var fileTP = this.files[i].name.split(".");
                      var newName = fileTP[0] + "-goldfruit.jpeg";
                      img.setAttribute("file-name", newName);
                        // } catch (error) {
                        //     console.log(error);
                        // }

                    }
                    this.isLoading = false;
                }
            },
            downloadAll() {
                if (this.isLoading) return;
                var images = document.getElementsByClassName("img-combine");
                var srcList = [];
                var i = 0;
                setInterval(function () {
                    if (images.length > i) {
                        srcList.push(images[i].src);
                        var link = document.createElement("a");
                        link.id = i;
                        link.download = images[i].getAttribute("file-name");
                        link.href = images[i].src;
                        if (images[i].src) {
                            link.click();
                        }

                        i++;
                    }
                }, 500);
            },
        },
    };
</script>